<template>
  <div class="auth-wrapper auth-v2">
    <AuthHeader />
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ $t('Forgot Password') }} 🔒
          </b-card-title>

          <!-- form -->
          <validation-observer
            v-if="!nextStep"
            ref="sendEmailForm"
          >
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="sendEmailForm"
            >
              <div>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('labels.email_phone')"
                  vid="value_email_or_mobile"
                  rules="required|custom_validate"
                >
                  <b-form-group
                    :label="$t('labels.email_phone')"
                  >
                    <b-form-input
                      v-model="value_email_or_mobile"
                      type="text"
                      :placeholder="$t('labels.email_phone')"
                    />
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
              <b-button
                :disabled="loading"
                type="submit"
                variant="primary"
                block
              >
                {{ $t('Restore password') }}
              </b-button>
            </b-form>
          </validation-observer>

          <validation-observer
            v-if="nextStep && !finalStep"
            ref="verifyCodeForm"
          >
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="verifyCodeForm"
            >
              <div>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('labels.code')"
                  vid="code"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('labels.please_enter_code')"
                  >
                    <v-otp-input
                      ref="otpInput"
                      input-classes="otp-input"
                      separator="-"
                      :num-inputs="4"
                      :should-auto-focus="true"
                      :is-input-num="true"
                      :value="''"
                      class="otp-input-outer my-2"
                      @on-change="handleOnChange"
                      @on-complete="handleOnComplete"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('labels.submit') }}
              </b-button>
            </b-form>
          </validation-observer>

          <validation-observer
            ref="resetPasswordObserver"
            v-show="finalStep"
          >
            <b-form
              ref="resetPasswordForm"
              class="auth-forgot-password-form mt-2"
              novalidate
              @submit.prevent="resetPasswordForm"
            >
              <div>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('labels.password')"
                  vid="new_password"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('labels.password')"
                  >
                    <b-form-input
                      v-model="form.new_password"
                      type="password"
                      :placeholder="$t('labels.password')"
                      autocomplete="new-password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
              <div>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('labels.password_confirmation')"
                  vid="new_password_confirmation"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('labels.password_confirmation')"
                  >
                    <b-form-input
                      v-model="form.new_password_confirmation"
                      type="password"
                      :placeholder="$t('labels.password_confirmation')"
                      autocomplete="new-password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('labels.submit') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              {{ $t('Back to login') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardTitle, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import AuthHeader from '@/components/AuthHeader'

extend('custom_validate', {
  validate: value => {
    const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    const phoneRegex = /^(?:00|\+)[0-9\\s.\\-]{10,14}$/ // Change this regex based on requirement
    const isValidEmail = emailRegex.test(value)
    const isValidPhone = phoneRegex.test(value)
    return !(!isValidEmail && !isValidPhone)
  },
  message: 'Enter Valid Phone/Email',
})
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    ValidationProvider,
    ValidationObserver,
    AuthHeader,
  },
  data() {
    return {
      finalStep: false,
      loading: false,
      value_email_or_mobile: '',
      nextStep: false,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      required,
      verify: {
        code: null,
      },
      form: {
        new_password: null,
        new_password_confirmation: null,
      },
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    handleOnComplete(value) {
      this.verify.code = value
    },
    handleOnChange(value) {
      this.verify.code = value
    },
    sendEmailForm() {
      this.$refs.sendEmailForm.validate()
        .then(success => {
          if (success) {
            this.loading = true
            this.axios.post('/forgot-password', {
              value_email_or_mobile: this.value_email_or_mobile,
            })
              .then(response => {
                useJwt.setToken(response.data.data.token)
                useJwt.setRefreshToken(response.data.data.token)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: response.data.message,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                  },
                })
                this.nextStep = true
                this.loading = false
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-start',
                  props: {
                    title: this.$t('Error'),
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error.response.data.message,
                  },
                })
                this.nextStep = false
                this.loading = false
              })
          }
        })
    },
    verifyCodeForm() {
      this.$refs.resetPasswordForm.reset()
      this.$refs.verifyCodeForm.validate()
        .then(success => {
          if (success) {
            this.axios.post('/forgot-password/verify', {
              code: this.verify.code,
              token: useJwt.getToken(),
            })
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: response.data.message,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                  },
                })
                this.finalStep = true
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-start',
                  props: {
                    title: this.$t('Error'),
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error.response.data.message,
                  },
                })
              })
          }
        })
    },
    resetPasswordForm() {
      this.$refs.resetPasswordObserver.validate()
        .then(success => {
          if (success) {
            this.axios.post('/reset-password', {
              token: useJwt.getToken(),
              new_password: this.form.new_password,
              new_password_confirmation: this.form.new_password_confirmation,
            })
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: response.data.message,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                  },
                })
                this.$router.push({ name: 'auth-login' })
                this.nextStep = false
                this.finalStep = false
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-start',
                  props: {
                    title: this.$t('Error'),
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error.response.data.message,
                  },
                })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';

[dir=rtl] .auth-forgot-password-form .otp-input-outer {
  direction: ltr !important;
}

.auth-forgot-password-form .otp-input-outer {
  justify-content: center;
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
